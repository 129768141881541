@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes blank {
    0% {
        opacity: 0;
    }

    40% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

@keyframes moon-fadein {
    from {
        opacity: 0;
        transform: scale(0.5);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes text-fadein {
    from {
        background-position-x: 100%;
    }

    to {
        background-position-x: 0%;
    }
}

.loader {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: 0;
    }

    > span {
        display: block;
        width: 100%;
        max-width: 500px;
        max-width: clamp(100px, 60vh, 500px);

        > span {
            position: relative;
            display: block;
            width: 100%;
            padding-top: 100%;
            height: 0;

            .moon {
                position: absolute;
                width: 100%;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                opacity: 0;
            }

            .owl {
                position: absolute;
                display: block;
                width: 100%;
                top: 0;
                left: 50%;
                opacity: 0;
                transform: translate(-50%, 6.5%) scale(0.8);
            }

            .owl-eyes {
                position: absolute;
                width: 100%;
                top: 0;
                left: 50%;
                opacity: 0;
                transform: translate(-50%, 52.8%) scale(0.18);
            }
        }
    }

    > h2 {
        font-family: "Montserrat";
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        color: white;
        margin-top: 40px;
        text-align: center;
        padding: 16px;
        z-index: 1;

        -webkit-text-fill-color: transparent;
        background: linear-gradient(90deg, white 0%, white 40%, transparent 60%, transparent 100%);
        background-size: 250% 100%;
        background-position-x: 100%;
        background-repeat: no-repeat;
        -webkit-background-clip: text;
        background-clip: text;
    }

    > div {
        position: relative;
        width: 400px;
        max-width: 80%;
        height: 4px;
        margin-top: 24px;
        border-radius: 4px;
        overflow: hidden;
        opacity: 1;
        transition: opacity 0.3s 1s;

        &::before {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            opacity: 0;
            width: 100%;
            height: 100%;
            background-color: #393939;
        }

        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            border-radius: 2px;
            width: var(--progress);
            background-color: white;
            opacity: 0;
            transition: width 0.1s;
        }

        &.out {
            opacity: 0;
        }
    }

    > h3 {
        position: relative;
        width: 100%;
        margin-top: -20px;

        font-family: "Montserrat";
        font-weight: 700;
        font-size: 24px;
        line-height: 24px;
        text-align: center;
        color: white;
        opacity: 0;

        &:after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: black;
            opacity: 0;
            transition: opacity 0.3s;
        }

        &.in {
            animation: fadein 0.6s 1.5s alternate infinite;
        }

        &.out:after {
            opacity: 1;
        }
    }

    &.mobile {
        > span {
            max-width: 300px;
        }

        > h2 {
            font-size: 12px;
            line-height: 14px;
            margin-top: 12px;
            padding: 8px;
        }

        > h3 {
            font-size: 20px;
            line-height: 30px;
            margin-top: -14px;
        }

        @media (orientation: landscape) {
            > span {
                max-width: 300px;
                max-width: clamp(100px, 60vh, 300px);
            }

            > div {
                margin-top: 8px;
            }

            > h2 {
                margin-top: 0;
            }
        }
    }

    &.in,
    &.out {
        &::before {
            animation: fadein 1s 1s forwards;
        }

        > span {
            > span {
                .moon {
                    animation: moon-fadein 1s 1.3s forwards;
                }

                .owl {
                    animation: fadein 0.3s 0.3s forwards;
                }

                .owl-eyes {
                    animation: fadein 1s 2s forwards;
                }
            }
        }

        > h2 {
            animation: text-fadein 3s 2.3s forwards;
        }

        > div::before,
        > div::after {
            animation: fadein 1s 3s forwards;
        }
    }

    &.out {
        opacity: 0;
        transition: opacity 0.3s;
        pointer-events: none;
    }
}
