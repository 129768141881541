@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,600;0,700;1,700;1,800&amp;display=block");
@import url("https://fonts.googleapis.com/css2?family=Fira+Code:wght@600&amp;display=swap");
@import "./themes/colors.scss";

:root,
html,
body,
#root {
    height: 100%;
    overflow-x: hidden;
}

body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    background: $background;
    margin: 0;

    &,
    & * {
        font-family: "Montserrat";
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
}

/* SELECTION */
::-moz-selection {
    background: rgba(255, 255, 255, 0.3) !important;
    color: rgba(0, 0, 0, 0.9) !important;
}

::selection {
    background: rgba(255, 255, 255, 0.3) !important;
    color: rgba(0, 0, 0, 0.9) !important;
}

*:not(input) {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* SCROLL */
* {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;

    &::-webkit-scrollbar {
        width: 3px;
        height: 3px;
        background: transparent;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
        border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
        border: 1px solid transparent;
        background: transparent;
        border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb:hover {
        border-width: 0px;
    }

    &:hover {
        &::-webkit-scrollbar-track {
            // background: @base;
        }

        &::-webkit-scrollbar-thumb {
            // border-color: @base;
            background: fade-out($color: black, $amount: 0.6);
        }
    }
}

/* TRANSITIONS */
.fade,
.enter-delayed-fade,
.exit-delayed-fade,
.delayed-fade {
    &-exit {
        opacity: 1 !important;
        display: block !important;
    }

    &-exit-active,
    &-exit-done {
        opacity: 0 !important;
    }

    &-exit-done {
        display: none !important;
    }

    &-enter {
        opacity: 0 !important;
        display: block !important;
    }

    &-enter-done {
        display: block !important;
    }

    &-enter-active,
    &-enter-done {
        opacity: 1 !important;
    }

    &-enter-active,
    &-exit-active {
        transition: opacity 0.3s;
    }
}

.enter-delayed-fade,
.delayed-fade {
    &-enter-active {
        transition: opacity 0.3s;
    }
}

.exit-delayed-fade,
.delayed-fade {
    &-exit-active {
        transition: opacity 0.3s;
    }
}
