.defuse {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    > .renderer,
    > .splash,
    > .overlays {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }

    > .overlays {
        z-index: 10000;
        pointer-events: none;
        & > * {
            pointer-events: all;
        }
    }

    > .splash {
        z-index: 10001;
    }

    > *:not(.renderer):not(.splash) {
        opacity: 0;
        transition: opacity 0.3s;
    }

    &.game.entered > *:not(.renderer):not(.splash) {
        opacity: 1;
    }

    > footer {
        bottom: -100px;
        transition: opacity 0.3s, bottom 1s !important;
    }

    &.entered > footer {
        bottom: 0;
    }

    > .debug {
        position: absolute;
        right: 16px;
        top: 16px;
        padding: 16px;
        border-radius: 4px;
        background: rgba($color: #000, $alpha: 0.6);
        transform: scale(var(--scale));
        transform-origin: top right;

        > ul {
            list-style: none;
            padding: 0;
            margin: 0;

            > li:not(:first-child) {
                margin-top: 8px;
            }

            button {
                width: 100%;
                text-align: center;
                text-transform: capitalize;

                height: 40px;
                min-height: 40px;
                > span {
                    font-size: 16px;
                }
            }
        }
    }

    &.mobile > .debug {
        top: 24px;
        right: 64px;

        gap: 8px;
        padding: 8px;

        > ul {
            display: flex;
            flex-direction: row;

            > li:not(:first-child) {
                margin-top: 0;
                margin-left: 4px;
            }
        }

        @media (orientation: portrait) {
            bottom: 0;
            left: 50%;
            top: auto;
            right: auto;

            transform: translate(-50%, -110%) scale(var(--scale, 1));
            transform-origin: center bottom;
        }
    }
}
