.button {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: none;
    transition: all 0.3s;
    font-size: 16px;
    line-height: 20px;
    white-space: nowrap;
    font-weight: 700;
    color: black;

    > span {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        transition: all 0.3s;
        overflow: hidden;
        z-index: 1;

        > svg {
            width: 1em;
            height: 1em;
        }
    }

    &,
    &:active,
    &:focus {
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        -webkit-tap-highlight-color: transparent;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        outline: none;
        box-shadow: none;
    }

    &:active:not(:disabled) {
        > span {
            transform: scale(0.9);
        }
    }

    &:disabled {
        opacity: 0.5;
    }

    &:not(:disabled) {
        cursor: pointer;
    }

    &.normal {
        min-width: 40px;
        min-height: 40px;
        border-radius: 4px;
        background: rgba($color: #000, $alpha: 0.7);
        padding: 8px 10px;
        color: white;

        &.toggle {
            padding-right: 24px;
            padding-left: 24px;

            &::after {
                content: "";
                display: block;
                position: absolute;
                top: 8px;
                right: 8px;
                border-radius: 4px;
                width: 8px;
                height: 8px;
                background-color: #ccc;
                transition: all 0.3s;
            }

            &.active {
                &::after {
                    background-color: #76ff60;
                }
            }
        }

        @media (hover: hover) and (pointer: fine) {
            &:hover:not(:disabled) {
                background: rgba($color: #000, $alpha: 0.9);
            }
        }

        &:active:not(:disabled) {
            background: rgba($color: #000, $alpha: 0.9);
        }
    }

    &.form {
        min-width: 40px;
        min-height: 40px;
        border-radius: 4px;
        background: transparent;
        padding: 8px 10px;
        color: white;
        border: 2px solid white;
        font-size: 24px;
        transition: opacity 0.3s;

        &.toggle.active {
            background-color: white;
            color: #000;
        }

        @media (hover: hover) and (pointer: fine) {
            &:hover:not(:disabled):not(.active) {
                background: rgba($color: #fff, $alpha: 0.2);
            }
        }

        &:active:not(:disabled):not(.active) {
            background: rgba($color: #fff, $alpha: 0.2);
        }
    }

    &.game {
        min-width: 56px;
        min-height: 56px;
        background: linear-gradient(180deg, #cccccc 0%, #818181 100%);
        border-radius: 4px;
        color: #cccccc;
        font-size: 20px;
        line-height: 30px;
        font-weight: 700;
        padding: 0 8px;

        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 2px;
            left: 2px;
            bottom: 2px;
            right: 2px;

            transition: all 0.3s;

            background: #670302;
            box-shadow: inset 0px 0px 16px #000000;
            border-radius: 2px;
            z-index: 0;
        }

        &::after {
            content: "";
            display: block;
            position: absolute;
            top: 6px;
            left: 6px;
            bottom: 6px;
            right: 6px;

            transition: all 0.3s;

            background: linear-gradient(180deg, #bf0200 0%, rgba(191, 2, 0, 0) 100%);
            box-shadow: inset 0px 0px 12px #670302;
            opacity: 0.5;
            border-radius: 2px;
            z-index: 0;
        }

        @media (hover: hover) and (pointer: fine) {
            &:hover:not(:disabled) {
                &::after {
                    opacity: 1;
                }

                > span {
                    color: #fff;
                }
            }
        }

        &:active:not(:disabled) {
            &::after {
                opacity: 1;
            }

            > span {
                color: #fff;
            }
        }
    }

    &.icon {
        position: relative;
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: none;
        transition: all 0.3s;

        min-width: 24px;
        min-height: 24px;
        border-radius: 0;
        background: transparent;
        font-size: 24px;
        line-height: 24px;
        color: white;
        padding: 0;
    }
}
